// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$prism-palette: ( 50: $prism-primary-color, 100: $prism-primary-color, 200: $prism-primary-color, 300: $prism-primary-color, 400: $prism-primary-color, 500: $prism-primary-color, 600: $prism-primary-color, 700: $prism-primary-color, 800: $prism-primary-color, 900: $prism-primary-color, A100: $prism-secondary-color, A200: $prism-secondary-color, A400: $prism-secondary-color, contrast: ( 50: black, 100: black, 200: black, 300: black, 400: black, 500: black, 600: black, 700: black, 800: black, 900: black, A100: white, A200: white, A400: white, ) );

$Client-primary: mat.m2-define-palette($prism-palette);
$Client-accent: mat.m2-define-palette($prism-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Client-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Client-theme: mat.m2-define-light-theme(( color: ( primary: $Client-primary, accent: $Client-accent, warn: $Client-warn, ) ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Client-theme);


html, body {
  height: 100%;
}

body {
  margin: 0;
}

* {
  font-family: 'Montserrat', sans-serif;
}
