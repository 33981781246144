@import "variables";
@import "theme";
@import 'ngx-toastr/toastr';
@import '~quill/dist/quill.snow.css';
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import "dialog";
@import "general";
@import "table";

.nav-active-item {
  text-decoration: underline !important;
}

.nav-item {
    color: #777 !important;
}

.main {
    background-color: $prism-primary-color;
}

.mat-tab-content-container {
    margin-top: 20px;
}

// Validation
input.ng-invalid.ng-touched {
  border: red 1px solid;
}

// Other
html, body { height: 100%; }
body { margin: 0; }
a {
  text-decoration: none !important;
}

// GC TODO material 'hacks'
mat-form-field {
  width: 100%;
}

.accent-button {
  color: white !important;
}

.green-button {
  color: white !important;
  background-color: #50C878 !important;
}

.red-button {
  color: white !important;
  background-color: #FF5733 !important;
}

.button {
    font-size: 14px !important;
}

.readonly-label {
    font-weight: bold;
}

.readonly-content {
    padding-top:10px;
    white-space:pre-line;
}

.readonly-section {
  border-bottom: solid thin rgba(0, 0, 0, 0.12);
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: black;
}
