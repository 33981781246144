/*#region Workaround for anchoring action buttons to the bottom: https://github.com/angular/components/issues/4609 */
::ng-deep .mat-dialog-container {
  display: flex;
}

.dialog-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dialog-container .mat-dialog-content {
  flex-grow: 1;
}

/*#endregion*/

.dialog-mat-tab-content-container {
    overflow-y: hidden;
    padding-top: 5px;
    padding-right: 5px;
}
