td {
    word-break: break-word;
}

.mat-cell-end {
  text-align: end !important;
}

.table-button {
  justify-content: left !important;
  text-align: left;
  min-height: 36px !important;
  height: auto !important;
}

.no-data-row {
  padding-top: 16px !important;
  padding-left: 16px !important;
}
