.row-highlight {
  background-color: #f9f9f9 !important;
}

.list-card {
  min-height: 90vh;
  height: 100%;
}

.list-button-row .mat-mdc-button-base {
  margin: 0 6px 6px 0;
}

.document-button {
    margin-left: 6px;
}

.file-input {
  display: none;
}

.info-icon-button {
  cursor: pointer;
}

.checkbox-menu-item {
  cursor: default !important;
  background-color: transparent !important;
}
